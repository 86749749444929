import {on} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "@elements/data-set-utils";

const defaultSelectors = {
    base: '.js-select-tracking'
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createSelectTracking(
            baseElement,
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createSelectTracking(baseElement) {
    on('change', function(evt) {
        let selectElement = baseElement;
        let data = {
            ...getPrefixedDataSet('select-tracking', selectElement)
        };

        window.dataLayer = window['dataLayer'] || [];

        let dataLayerData = {
            'event': 'genericGtmEvent',
            'genericEventCategory': data.category,
            ...(exists(data.action) ? {'genericEventAction': data.action}: null),
            ...(exists(selectElement.value) ? {'genericEventLabel': selectElement.options[selectElement.selectedIndex].text}: null),
        };

        window.dataLayer.push(dataLayerData);
        console.log('GTM Tracking - dataLayer push:', dataLayerData);
    }, baseElement)
}

function exists(x) {
    return x !== null && typeof x !== 'undefined'
}
