"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {find,addClass, removeClass, on, closest} from '@elements/dom-utils';

const selectors = {
    btn: '.js-search-toggle',
    overlay: '.js-search-toggle__overlay',
    close: '.js-search-toggle__close',
    closeMobile: '.js-search-toggle__close-mobile',
    input: '.js-search-toggle__input',
    resetInput: '.js-search-toggle__reset-input',
    navItemOpen: '.js-search-toggle__nav-item-open',
    navItemClose: '.js-search-toggle__nav-item-close'
};

export function init () {
    onFind(selectors.btn, function (baseElement) {
        let overlay = find(selectors.overlay);
        let input = find(selectors.input);
        let navItemOpen = find(selectors.navItemOpen);
        let navItemClose = find(selectors.navItemClose);
        let close = find(selectors.close);
        let closeMobile = find(selectors.closeMobile);

        on('click', function () {
            addClass('is-open', overlay);
            addClass('overlay-open', document.getElementsByTagName('body')[0]);
            addClass('d-none', navItemOpen);
            removeClass('d-none', navItemClose);
            focusInput(input);
            bodyClickHandler(overlay);
        }, baseElement);

        if(close) {
            on('click', function (evt) {
                evt.preventDefault();
                removeClass('is-open', overlay);
                removeClass('overlay-open', document.getElementsByTagName('body')[0]);
                addClass('d-none', navItemClose);
                removeClass('d-none', navItemOpen);
                bodyClickHandler(overlay);
            }, close);
        }

        if(closeMobile) {
            on('click', function (evt) {
                evt.preventDefault();
                removeClass('is-open', overlay);
                removeClass('overlay-open', document.getElementsByTagName('body')[0]);
            }, closeMobile);
        }
        resetInput(input);
    });
}

function bodyClickHandler(parent) {
    window.addEventListener("click", function _listener(evt) {
        if (!parent.contains(evt.target)) {
            removeClass('is-open', parent);
            removeClass('overlay-open', document.getElementsByTagName('body')[0]);

            window.removeEventListener("click", _listener, true);
        }
    }, true);
}

function focusInput(input) {
    // iOS needs focusing directly in the click event
    input.focus();

    let timeout = setTimeout(function () {
        /* timeout is needed because some browsers don't allow focusing a element
        if it is not visible */
        input.focus();
        clearTimeout(timeout);
    }, 500);
}

function resetInput(input) {
    let resetBtn = find(selectors.resetInput);

    on('click', function(evt) {
        evt.preventDefault();
        input.value = '';
    }, resetBtn);
}

