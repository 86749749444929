import {on, addClass, removeClass} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "@elements/data-set-utils";

const defaultSelectors = {
    base: '.js-small-sticky-area'
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
            on('mouseenter', function () {
                addClass('active', baseElement.firstElementChild);
            }, baseElement)
    });
    onFind(selectors.base, function (baseElement) {
        on('mouseleave', function () {
            removeClass('active', baseElement.firstElementChild);
        }, baseElement)
    });




}

