import * as gtm from '@elements/tracking/src/gtm';
import * as matomo from '@elements/tracking/src/matomo';
import {on} from "@elements/dom-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import {onFind} from "@elements/init-modules-in-scope";

export const initGtmEventTracking = createEventTracking(gtm);
export const initMatomoEventTracking = createEventTracking(matomo);

const isTouchDevice = () => {
    return window.matchMedia("(max-width:767px), (pointer: coarse)").matches
}

function createEventTracking({
         setup,
         trackEvent
     }) {
    return ({
                dataAttributePrefix = 'only-desktop-event-tracking',
                debug = false
            } = {}) => {
        setup({debug});

        if(isTouchDevice) {
            return;
        }

        onFind(`[data-${dataAttributePrefix}-category]`, function(trackingElement) {
            on('click', function(evt) {
                let clickedElement = evt.currentTarget;
                let data = {};

                if (clickedElement.tagName === 'A') {
                    data.action = clickedElement.getAttribute('href');
                    data.label = trim(clickedElement.textContent);
                }

                data = {
                    ...data,
                    ...getPrefixedDataSet(dataAttributePrefix, clickedElement)
                };

                trackEvent(data, {debug});
            }, trackingElement);
        })
    }
}

function trim(str) {
    return str.replace(/\s\s+/g, ' ').trim();
}
