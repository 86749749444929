'use strict'

import {onFind} from "@elements/init-modules-in-scope";
import {findIn, hasClass, addClass, on} from "@elements/dom-utils";
import { WidgetInstance } from "friendly-challenge";

export function init() {
    onFind('.js-friendly-recaptcha', function(element) {
        let submitBtn = findIn('button[type="submit"]', element);
        let recaptcha = findIn('.js-friendly-recaptcha__captcha', element);

        if(!hasClass('recaptcha-finished', submitBtn)) {
            submitBtn.setAttribute('disabled', 'disabled');
        }

        function doneCallback(solution) {
            addClass('recaptcha-finished', submitBtn);
            submitBtn.removeAttribute('disabled');
        }

        let options = {
            doneCallback: doneCallback,
            startMode: "focus",
        }

        let widget = new WidgetInstance(recaptcha, options);

        on('focusin', function () {
            widget.start();
        }, element);
    });
}
