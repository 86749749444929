import {onFind} from "@elements/init-modules-in-scope";
import {addClass} from "@elements/dom-utils";

export function init () {
    onFind('.wysiwyg table', function (table) {
        let wrapper = document.createElement('div');
        let wrapperTwo = document.createElement('div');

        addClass('table-responsive', wrapper);
        addClass('table-mobile-scroll-gradient', wrapperTwo);

        table.parentNode.insertBefore(wrapperTwo, table);
        wrapperTwo.appendChild(table);

        table.parentNode.insertBefore(wrapper, table);
        wrapper.appendChild(table);
    });
}