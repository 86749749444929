"use strict";

import {on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {

    onFind('.js-dropdown-persist', function (dropdown) {
        const dropdownClickHandler = (evt) => {
            evt.stopPropagation();
        };

        on('click', (evt) => dropdownClickHandler(evt), dropdown);
    });

}