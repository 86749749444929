"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findAllIn, findIn } from "@elements/dom-utils";

export function init() {

    let headerHeight;

    onFind('.main-navbar', function (navigation) {

        headerHeight= navigation.clientHeight;

    })

    onFind('.js-product-selection-form', function (form) {
        let inputs = findAllIn('input', form);
        let selectionButton = findIn('.js-product-selection-form__selection', form);
        let orderButton = findIn('.js-product-selection-form__order', form);
        let orderLink = findIn('.js-product-selection-form__order-link', form);
        let infoButton = findIn('.js-product-selection-form__infos', form);
        let pharmacyButton = findIn('.js-product-selection-form__pharmacy', form);

        function calcDropdownHeight() {
            let ctaBarHeight = findIn('.js-product-selection-bar__sticky-cta', form).clientHeight;
            let dropdownElement = findIn('.js-product-selection-bar__dropdown', form);
            let dropdownHeight = ctaBarHeight+ headerHeight;
            const isMobile = () => matchMedia('(max-width: 767px)').matches;

            dropdownElement.style.maxHeight =  isMobile()  ? `${(window.innerHeight - (dropdownHeight))}px` : "";
            dropdownElement.style.height =  isMobile()  ? `${(window.innerHeight - (dropdownHeight))}px` : "";
        }

        calcDropdownHeight();
        inputs.map(function(item) {
            on('change', function(evt) {
                let dropdown = evt.target;

                selectionButton.innerHTML = dropdown.getAttribute('data-name');
                selectionButton.classList.add('product-selection-bar__btn-no-styling');
                selectionButton.setAttribute('data-selected', dropdown.getAttribute('data-value'));
                selectionButton.setAttribute('data-selected', dropdown.getAttribute('data-value'));

                if(dropdown.getAttribute('data-link') !== '/' && dropdown.getAttribute('data-link') !== '#' && dropdown.getAttribute('data-link') !== null && dropdown.getAttribute('data-link') !== undefined)
                {
                    orderButton.firstElementChild.href = dropdown.getAttribute('data-link');
                    orderButton.removeAttribute('disabled');
                }else{
                    orderButton.disabled= true;
                }

                if(dropdown.getAttribute('data-pharmacy-link') !== '/' && dropdown.getAttribute('data-pharmacy-link') !== '#' && dropdown.getAttribute('data-pharmacy-link') !== null && dropdown.getAttribute('data-pharmacy-link') !== undefined){
                    pharmacyButton.removeAttribute('disabled');
                    pharmacyButton.firstElementChild.href = dropdown.getAttribute('data-pharmacy-link')+'?openModal=shippingModal';
                }
                else{
                    pharmacyButton.disabled= true;
                }

                //tracking

                orderLink.setAttribute('data-event-tracking-category', dropdown.getAttribute('data-name'));
                orderLink.setAttribute('data-event-tracking-action', `${dropdown.getAttribute('data-package-amount')} | Online kaufen`);
                console.log(orderButton);
                orderLink.setAttribute('data-value', `productSize ${dropdown.getAttribute('data-package-amount')}`);
                orderLink.setAttribute('data-name', dropdown.getAttribute('data-name'));


                console.log(pharmacyButton.firstChild);
                infoButton.href = dropdown.getAttribute('data-info-link');
                infoButton.removeAttribute('hidden');

                calcDropdownHeight();
            }, item)
        })
    });
}
