import {onFind} from "@elements/init-modules-in-scope";

import * as nav from './nav';
nav.init();

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('[data-bs-toggle="dropdown"]',function (element) {
    new Dropdown(element);
});

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import * as ajaxModal from './ajax-modal';
ajaxModal.init();

import * as fadeIn from './fade-in';
fadeIn.init();

import * as tableResponsive from './table-responsive';
tableResponsive.init();

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as searchToggle from './search-toggle';
searchToggle.init();

import * as multiselectDropdown from './multiselect-dropdown';
multiselectDropdown.init();

import * as dropdownPersist from './dropdown-persist';
dropdownPersist.init();

import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as actionChanger from '@elements/action-changer';
actionChanger.init();

import * as formTracking from './form-tracking';
formTracking.init();

import * as selectTracking from './select-tracking';
selectTracking.init();

import * as productSelectionForm from './product-selection-form';
productSelectionForm.init();


import * as smallStickyArea from './small-sticky-area';
smallStickyArea.init();

import * as tracking from "@elements/tracking";
let options = {debug: true}; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as onlyDesktopTracking from './only-desktop-tracking';
onlyDesktopTracking.initGtmEventTracking(options);

import * as friendlyRecaptcha from './friendlyRecaptcha';
friendlyRecaptcha.init();